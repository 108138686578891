import { useCases } from '@capturi/api-cases'
import {
  TextFilterValues,
  mapTextFilterValuesToRequestModel,
} from '@capturi/api-filters'
import Icon_EmptyState from '@capturi/assets/images/EmptyState.svg'
import {
  Channel,
  DEFAULT_TEXT_STATE,
  FilterPeriodSelectContainer,
  TextFilter,
  TextSegmentBuilderState,
  toTextFilterValues,
  useFilterPeriodContext,
  useSegmentStatesContext,
} from '@capturi/filters'
import { ErrorBoundary, usePageTitle } from '@capturi/react-utils'
import {
  ContentPlaceholder,
  PageHeading,
  useToast,
} from '@capturi/ui-components'
import { Box, Flex, HStack, MenuItem, Text } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { format } from 'date-fns/fp'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { MdCopyAll } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

import CaseDetails from '../../components/CaseDetails'
import conversationRoutes from '../Conversations/routes'
import CaseList from './CaseList/CaseList'

const NoConversationFound: React.FC = () => (
  <ContentPlaceholder.Container mt={8}>
    <ContentPlaceholder.Image as={Icon_EmptyState} />
    <ContentPlaceholder.Heading>
      <Trans>No ticket selected</Trans>
    </ContentPlaceholder.Heading>
    <ContentPlaceholder.Body>
      <Trans>Choose a ticket from the list to the left</Trans>
    </ContentPlaceholder.Body>
  </ContentPlaceholder.Container>
)

const formatToExcelDate = format('dd/MM/yyyy HH.mm')

const CaseOverview: React.FC = () => {
  usePageTitle(t`Text`)
  const { period } = useFilterPeriodContext()
  const toast = useToast()

  const [selectedCaseUid, setSelectedCaseUid] = useState<string>()

  const { states, updateState } = useSegmentStatesContext()
  let initialValue = { ...DEFAULT_TEXT_STATE }
  if (states.length > 0 && states[0].channel === 'email') {
    if (
      states[0].savedTextFilter &&
      states[0].savedTextFilter.accessLevel !== 'None'
    ) {
      initialValue = toTextFilterValues(states[0].savedTextFilter.values)
    } else {
      initialValue = states[0].values
    }
  }
  const [filter, setFilter] = useState<TextFilterValues>(initialValue)

  const filterRequestModel = useMemo(
    () => mapTextFilterValuesToRequestModel(filter),
    [filter],
  )

  const {
    data: cases,
    fetchNextPage,
    isFetching,
    error,
    hasNextPage,
    isFetchingNextPage,
  } = useCases(filterRequestModel, period)

  const handleUpdateFilter = useCallback(
    (state: TextSegmentBuilderState) => {
      setFilter(state.values)
      updateState({ state, index: 0 })
    },
    [updateState],
  )

  const navigate = useNavigate()

  const handleChannelChange = useCallback(
    (channel: Channel) => {
      if (channel === 'phone') {
        if (states.length > 0) {
          updateState({ state: { channel: 'phone', values: {} }, index: 0 })
        }
        navigate(conversationRoutes.list())
      }
    },
    [navigate, states.length, updateState],
  )

  const handleCopyCases = (): void => {
    if (!cases) return

    //We map it a little funky, so it comes in columns and rows when pasting into excel
    const headerRow = [
      'ExternalUid',
      'Subject',
      'Inbox',
      'Created',
      'FirstEmail',
      'LastEmail',
    ].join('\t')
    const rows = cases.pages.flatMap((p) =>
      p.map((c) =>
        [
          c.externalUid,
          c.subject,
          c.inbox,
          formatToExcelDate(c.created),
          c.messageFlow.firstEmail,
          c.messageFlow.lastEmail,
        ].join('\t'),
      ),
    )

    const numberOfRows = rows.length
    navigator.clipboard.writeText(`${headerRow}\n${rows.join('\n')}`)
    toast({
      status: 'info',
      title: t`Copied ${numberOfRows} tickets to clipboard`,
    })
  }
  const contentRef = useRef<HTMLDivElement>(null)
  const contentHeight = contentRef.current
    ? `calc(100vh - ${contentRef.current.getBoundingClientRect().top}px)`
    : '100vh'
  return (
    <Flex
      className="conversations-container"
      flexDir="column"
      h="100%"
      overflowY="auto"
    >
      <Box mb={4} flex="0 1 auto">
        <Flex align="center" justify="space-between" mb={6}>
          <PageHeading>
            <Trans>All conversations</Trans>
          </PageHeading>
          <HStack>
            <FilterPeriodSelectContainer />
          </HStack>
        </Flex>
        <TextFilter
          segmentState={
            states.length > 0 && states[0].channel === 'email'
              ? states[0]
              : undefined
          }
          initialValue={filter}
          onSegmentStateChange={handleUpdateFilter}
          onChannelChange={handleChannelChange}
          showSaveButton={true}
          extraMenuItems={
            <MenuItem icon={<MdCopyAll />} onClick={handleCopyCases}>
              <Trans>Copy listed conversations to clipboard</Trans>
            </MenuItem>
          }
        />
      </Box>
      <ErrorBoundary>
        <Flex
          gap={3}
          w="100%"
          flex="1 1"
          overflowY="hidden"
          h="100%"
          ref={contentRef}
        >
          <Flex w="300px" overflowY="hidden" h={contentHeight}>
            {error != null ? (
              <Text>
                An error occurred. Please try and refreshing the page.
              </Text>
            ) : (
              <CaseList
                cases={cases}
                isFetchingCases={isFetching}
                fetchNextPage={hasNextPage ? fetchNextPage : null}
                isFetchingNextPage={isFetchingNextPage}
                selectedCaseUid={selectedCaseUid}
                onCaseSelected={setSelectedCaseUid}
                filter={filter}
              />
            )}
          </Flex>
          <Flex
            w="100%"
            h={contentHeight}
            overflowY="hidden"
            minH="75px"
            pr="0.75rem"
          >
            {selectedCaseUid ? (
              <CaseDetails
                caseUid={selectedCaseUid}
                onCaseDeleted={() => {
                  if (cases?.pages[0][0].uid === selectedCaseUid) {
                    setSelectedCaseUid?.(cases?.pages[0][1].uid)
                  } else {
                    setSelectedCaseUid?.(cases?.pages[0][0].uid)
                  }
                }}
              />
            ) : (
              <NoConversationFound />
            )}
          </Flex>
        </Flex>
      </ErrorBoundary>
    </Flex>
  )
}

export default CaseOverview
